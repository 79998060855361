import React, { Component, Fragment } from "react";
import Header from "../components/Header";
import Leftnav from "../components/Leftnav";
import Rightchat from "../components/Rightchat";
import Pagetitle from "../components/Pagetitle";
import Appfooter from "../components/Appfooter";
import Popupchat from "../components/Popupchat";

const storyList = [
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Hendrix Stamp",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Stephen Grider",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Mohannad Zitoun",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Aliqa Macale",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
  {
    imageUrl: "user.png",
    name: "Surfiya Zakir",
    email: "support@gmail.com",
    bgImage: "product.png",
  },
];

class Storie extends Component {
  render() {
    return (
      <Fragment>
        <Header />
        <Leftnav />
        <Rightchat />

        <div className="main-content right-chat-active">
          <div className="middle-sidebar-bottom">
            <div className="middle-sidebar-left pe-0">
              <div className="row">
                <div className="col-xl-12">
                  <Pagetitle title="Stories" />

                  <div className="row ps-2 pe-1">
                    {storyList.map((value, index) => (
                      <div key={index} className="col-md-3 col-xss-6 pe-2 ps-2">
                        <div
                          className="card h300 d-block border-0 shadow-xss rounded-3 bg-gradiant-bottom overflow-hidden mb-3 bg-image-cover"
                          style={{
                            backgroundImage: `url("assets/images/${value.bgImage}")`,
                          }}
                        >
                          <div className="card-body d-block w-100 position-absolute bottom-0 text-center">
                            <figure className="avatar ms-auto me-auto mb-0 position-relative w50 z-index-1">
                              <img
                                src={`assets/images/${value.imageUrl}`}
                                alt="avater"
                                className="float-right p-0 bg-white rounded-circle w-100 shadow-xss"
                              />
                            </figure>
                            <div className="clearfix"></div>
                            <h4 className="fw-600 position-relative z-index-1 ls-3 font-xssss text-white mt-2 mb-1">
                              {value.name}
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Popupchat />
        <Appfooter />
      </Fragment>
    );
  }
}

export default Storie;
